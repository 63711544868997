import React from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';
import Colors from './assets/style/Colors';
import { ToasterContainer } from './components/toaster/Toaster';
import AuthRoutes from './routes/AuthRoutes';
import DashboardRoutes from './routes/DashboardRoutes';
import ScrollToTop from './hooks/ScrollToTop';
import { useAuth } from 'context/UseContext';
import "@fontsource/poppins";

function App() {

  const { user } = useAuth();

  const theme = createTheme({
    palette: {
      primary: {
        main: Colors.primary,
        contrastText: Colors.white,
      },
      secondary: {
        main: Colors.secondary,
        contrastText: Colors.white,
      },
      danger: {
        main: Colors.danger,
        contrastText: Colors.white,
      },
    },
    typography: {
      fontFamily: "Poppins",
      h3: {
        fontSize: 28,
        fontWeight: 700,
      },
      h5: {
        fontSize: 24,
        fontWeight: 700,
      },
      h6: {
        fontSize: 22,
        fontWeight: 700,
      },
      subtitle1: {
        fontSize: 20,
        fontWeight: 600,
      },
      subtitle2: {
        fontSize: 18,
        fontWeight: 500
      },
      body1: {
        fontSize: 16,
        fontWeight: 500
      },
      body2: {
        fontSize: 14,
        fontWeight: 400
      },
      caption: {
        fontSize: 12,
        fontWeight: 300
      },
    },
  });

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>

        {/* ========== Toaster ========== */}
        <ToasterContainer />

        <Routes>

          <Route element={user?.access_token ? <Navigate to="/" /> : <Outlet />} >
            {AuthRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>

          <Route element={user?.access_token ? <Outlet /> : <Navigate to="/login" />} >
            {DashboardRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>

        </Routes>

        {/* ========== Scroll To Top ========== */}
        <ScrollToTop />

      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
