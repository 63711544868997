import React, { lazy } from 'react';

const Login = lazy(() => import('views/auth/Login'));

const AuthRoutes = [
  {
    path: "/login",
    component: <Login />,
  },
]

export default AuthRoutes
